.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  font-family: 'Martti';
}



.App .pageContentContainer {
  align-self: stretch;
  flex: 12;
  background-color: #fff;
}

@font-face {
  font-family: 'Martti';
  src: local('Martti'), url(/font/Martti-Regular.ttf);
}

@font-face {
    font-family: 'Martti';
    font-weight: bold;
    src: local('Martti'), url(/font/Martti-Bold.ttf);
}

@font-face {
    font-family: 'Martti';
    font-style: italic;
    src: local('Martti'), url(/font/Martti-Italic.ttf);
}

@font-face {
  font-family: 'Martti';
  font-weight: bold;
  font-style: italic;
  src: local('Martti'), url(/font/Martti-BoldItalic.ttf);
}


/** page content and footer containers without padding */
.App .MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
}

/** override these if need different width content area */
@media (min-width:600px) {
  .App .MuiContainer-root {
    width: 94%;
  }
  .MuiContainer-maxWidthSm {
    max-width: 600px;
  }
}
@media (min-width:960px) {
  .MuiContainer-maxWidthMd {
    max-width: 960px;
  }
}
@media (min-width:1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1280px;
  }
}
@media (min-width:1920px) {
  .MuiContainer-maxWidthXl {
    max-width: 1920px;
  }
}
